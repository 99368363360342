import React, { useState } from "react";
// import notification from "../../Assets/bell.png";
// import setting from "../../Assets/setting.png";
import emp from "../../Assets/employees.png";
import role from "../../Assets/role.png";
import logo from "../../Assets/logo12.png";
import prof from "../../Assets/user.png";
import iew from "../../Assets/transferW.png";
import imp from "../../Assets/import.png";
import exp from "../../Assets/export.png";
import prog from "../../Assets/monitor.png";
// import chat from "../../Assets/message.png";
// import analytics from "../../Assets/analytics.png";
import { Dropdown, Avatar } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
const { Option } = Select;
let items;
const profile =
  "https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg=";
const MobileHeader = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [err, setErr] = useState("");
  // const [show, setShow] = useState(false);
  const dataUser = useSelector((state) => state.dataUser);
  const email = useSelector((state) => state.email);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [err, setErr] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  const user = useSelector((state) => state.user);
  const [showImp, setShowImp] = useState(false);
  const [showExp, setShowExp] = useState(false);
  const token = useSelector((state) => state.token);
  const handleProgramClick = (programValue) => {
    setSelectedPrograms((prevSelected) => {
      if (prevSelected.includes(programValue)) {
        return prevSelected.filter((item) => item !== programValue);
      } else {
        return [...prevSelected, programValue];
      }
    });
  };
  if (user === "Super Admin") {
    items = [
      {
        label: "Profile",
        key: "4",
        icon: <img src={prof} alt="" className="w-4 h-4" />,
      },
      {
        label: "Employees",
        key: "1",
        icon: <img src={emp} alt="" className="w-4 h-4" />,
      },
      {
        label: "Roles & Privileges",
        key: "2",
        icon: <img src={role} alt="" className="w-4 h-4" />,
      },
      {
        label: "Programs",
        key: "6",
        icon: <img src={prog} alt="" className="w-4 h-4" />,
      },
      {
        label: "Logout",
        key: "3",
        icon: <LogoutOutlined className="some" />,
      },
    ];
  } else if (user === "Admin") {
    items = [
      {
        label: "Profile",
        key: "4",
        icon: <img src={prof} alt="" className="w-4 h-4" />,
      },
      {
        label: "Employees",
        key: "1",
        icon: <img src={emp} alt="" className="w-4 h-4" />,
      },
      // {
      //   label: "Roles & Privileges",
      //   key: "2",
      //   icon: <img src={role} alt="" className="w-4 h-4" />,
      // },
      {
        label: "Programs",
        key: "6",
        icon: <img src={prog} alt="" className="w-4 h-4" />,
      },
      {
        label: "Logout",
        key: "3",
        icon: <LogoutOutlined className="some" />,
      },
    ];
  } else {
    items = [
      // {
      //   label: "Employees",
      //   key: "1",
      //   icon: <img src={emp} alt="" className="w-4 h-4" />,
      // },
      // {
      //   label: "Roles & Privileges",
      //   key: "2",
      //   icon: <img src={role} alt="" className="w-4 h-4" />,
      // },
      {
        label: "Profile",
        key: "4",
        icon: <img src={prof} alt="" className="w-4 h-4" />,
      },
      {
        label: "Logout",
        key: "3",
        icon: <LogoutOutlined className="some" />,
      },
    ];
  }
  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const onClick = ({ key }) => {
    if (key === "3") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/sign-out`,
          {
            email: email,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch({ type: "authStateSet", num: "false" });
          dispatch({ type: "USER", num: "" });
          dispatch({ type: "EMAIL", num: "" });
          dispatch({
            type: "tokenSet",
            num: "",
          });
          dispatch({ type: "dataSet", num: [] });
          router("/");
        })
        .catch((err) => {
          // setErr(err?.response?.data?.message?.reason);
          // setShow(true);
        });
    }
    if (key === "2") {
      router("/Roles&Privilages");
    }
    if (key === "1") {
      router("/Dashboard/Employees");
    }
    if (key === "4") {
      router("/Dashboard/Profile");
    }
    if (key === "6") {
      router("/Programs");
    }
  };
  const items4 = [
    {
      label: "Import",
      key: "1",
      icon: (
        <img
          src={imp}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
    {
      label: "Export",
      key: "2",
      icon: (
        <img
          src={exp}
          alt="Scheduler Icon"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      ),
    },
  ];
  const onClickMenu4 = ({ key }) => {
    if (key === "1") {
      setShowImp(true);
    } else if (key === "2") {
      setShowExp(true);
    }
  };
  const programOptions = [
    { value: "tabAgent", label: "Agent" },
    { value: "tabAgentBranch", label: "Branch Agent" },
    { value: "tabBooklet", label: "Booklet" },
    { value: "tabClient", label: "Client" },
    { value: "tabCoupon", label: "Coupon" },
    { value: "tabDisbursementCycle", label: "Disbursement Cycle" },
    { value: "tabEconomical", label: "Economical" },
    { value: "tabLifeCertificate", label: "Life Certificate" },
    { value: "tabMedical", label: "Medical" },
    { value: "tabNationality", label: "Nationality" },
    { value: "tabOffice", label: "Office" },
    { value: "tabOfficeLocation", label: "Office Location" },
    { value: "tabPension", label: "Pension" },
    { value: "tabRegion", label: "Region" },
    { value: "tabRelationship", label: "Relationship" },
    { value: "tabRepresentative", label: "Representative" },
    { value: "tabServicePeriod", label: "Service Period" },
    { value: "tabServiceSubscription", label: "Service Subscription" },
    { value: "tabServiceType", label: "Service Type" },
    { value: "tabVillage", label: "Village" },
  ];

  const handleExport = () => {
    if (selectedPrograms.length === 0) {
      setErr("Please Select a Table to Export.");
      setShow(true);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/v1/processCSV/exportCSV`,
          {
            program: selectedPrograms,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/zip" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "exported_data.zip");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setShowExp(false);
          setSelectedPrograms([]);
        })
        .catch((err) => {
          setErr(err?.response?.data?.message?.description);
          setShow(true);
        });
    }
  };

  const handleProgramChange = (value) => {
    console.log("Value", value);
    setSelectedProgram(value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImport = () => {
    if (!selectedProgram || !file) {
      setErr("Please select a Table and upload a file.");
      setShow(true);
      return;
    }
    const formData = new FormData();
    formData.append("csv", file);
    formData.append("program", selectedProgram);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/processCSV/importCSV`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setShowConfirm(true);
        setShowImp(false);
        setSelectedProgram(null);
        setFile("");
        setTimeout(() => {
          setShowConfirm(false);
        }, 2000);
      })
      .catch((err) => {
        setErr(err?.response?.data?.message?.description);
        setShow(true);
      });
  };
  return (
    <header className="flex items-center w-full justify-between bg-primary py-3 px-4 gap-4 text-white">
      <div className="flex gap-2 items-center justify-center">
        <img
          onClick={() => {
            router("/DashBoard");
            // dispatch({ type: "SIDEBAR", num: "1" });
          }}
          src={logo}
          alt=""
          className="w-[2.5rem] cursor-pointer h-[2.5rem]"
        />
        <h1 className="text-xl font-bold">Dashboard</h1>
      </div>
      <div className="flex items-center md:gap-4 gap-2">
        <li
          className={`${
            user === "Super Admin" || user === "Admin"
              ? "text-[1.6rem] font-bold cursor-pointer list-none m-0 p-0"
              : "hidden"
          }`}
        >
          <Dropdown
            menu={{
              items: items4,
              onClick: onClickMenu4,
            }}
            placement="bottomRight"
            className="custom-dropdown"
          >
            <img src={iew} alt="" className="w-8 h-8 mr-4" />
          </Dropdown>
        </li>
        {/* <Popover content="Reports" placement="bottom">
          <button className="md:w-[2rem] w-[1.4rem] rounded-md p-2 md:h-[2rem] h-[1.4rem] bg-gray-50 hover:bg-gray-100 shadow-md flex items-center justify-center">
            <img src={analytics} alt="" />
          </button>
        </Popover>
        <Popover content="Notifications" placement="bottom">
          <button className="md:w-[2rem] w-[1.4rem] rounded-md p-2 md:h-[2rem] h-[1.4rem] bg-gray-50 hover:bg-gray-100 shadow-md flex items-center justify-center">
            <img src={notification} alt="" />
          </button>
        </Popover>
        <Popover content="Setting" placement="bottom">
          <button className="md:w-[2rem] w-[1.4rem] rounded-md p-2 md:h-[2rem] h-[1.4rem] bg-gray-50 hover:bg-gray-100 shadow-md flex items-center justify-center">
            <img src={setting} alt="" />
          </button>
        </Popover>
        <Popover content="Chat" placement="bottom">
          <button className="md:w-[2rem] w-[1.4rem] rounded-md p-2 md:h-[2rem] h-[1.4rem] bg-gray-50 hover:bg-gray-100 shadow-md flex items-center justify-center">
            <img src={chat} alt="" />
          </button>
        </Popover> */}
        <div className="cursor-pointer">
          <Dropdown
            menu={{
              items,
              onClick,
            }}
            isOpen={dropdownOpen}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <a onClick={(e) => e.preventDefault()}>
              <div className="flex items-center capitalize justify-between">
                <Avatar
                  src={
                    dataUser?.user?.avatar
                      ? `https://mhsss-profiles.s3.amazonaws.com/avatar_${dataUser?.user?.avatar}`
                      : profile
                  }
                  className="border-2  border-primary mr-2 w-14 h-14"
                />
              </div>
            </a>
          </Dropdown>
        </div>
      </div>
      <div
        className={`${
          showExp ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl sm:w-[30rem] w-[90%] max-h-[90%] overflow-auto pb-6 pt-6 flex items-center p-2 bg-white flex-col">
          <h1 className="text-[1rem] font-semibold">Export Tables</h1>
          <div className="w-full mb-4 p-2 rounded-lg mt-6">
            <h2 className="text-[#596F96] font-bold text-[0.8rem]">
              Select tables to download:-
            </h2>
            <div className="w-full flex flex-wrap items-center justify-center gap-2 p-2">
              {programOptions.map((program) => (
                <div
                  key={program.value}
                  className={`p-2 cursor-pointer mt-2 border px-5 rounded-full ${
                    selectedPrograms !== null &&
                    selectedPrograms.includes(program.value)
                      ? "bg-primary text-white"
                      : "bg-slate-50 text-black"
                  }`}
                  onClick={() => handleProgramClick(program.value)}
                >
                  {program.label}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex items-center justify-center gap-2">
            <button
              onClick={() => {
                setSelectedPrograms([]);
                setShowExp(false);
              }}
              type="button"
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] text-center w-[7rem] text-white bg-[#DE2827]"
            >
              Cancel
            </button>
            <button
              onClick={handleExport}
              type="button"
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] text-center w-[7rem] text-white bg-primary"
            >
              Export
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          showImp ? "" : "hidden"
        } w-full h-full fixed top-0 left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="rounded-xl sm:w-[30rem] w-[90%] pb-6 pt-6 flex items-center p-2 bg-white flex-col">
          <h1 className="text-[1rem] font-semibold">Import Tables</h1>
          <div className="w-full mb-2 p-2 rounded-lg mt-6">
            <h2 className="text-[#596F96] mb-1 font-bold text-[0.8rem]">
              Select a Tables to Import:
            </h2>
            <Select
              className="w-full"
              allowClear
              showSearch
              placeholder="Select a Table"
              onChange={(value) => handleProgramChange(value)}
              value={selectedProgram}
            >
              {programOptions.map((program) => (
                <Option key={program.value} value={program.value}>
                  {program.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="w-full mb-4 p-2 rounded-lg">
            <h2 className="text-[#596F96] mb-1 font-bold text-[0.8rem]">
              Upload CSV file:
            </h2>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="w-full"
            />
          </div>
          <div className="w-full flex items-center justify-center gap-2">
            <button
              onClick={() => {
                setShowImp(false);
                setSelectedProgram(null);
                setFile("");
              }}
              type="button"
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] text-center w-[7rem] text-white bg-[#DE2827]"
            >
              Cancel
            </button>
            <button
              onClick={handleImport}
              type="button"
              className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] text-center w-[7rem] text-white bg-primary"
            >
              Import
            </button>
          </div>
        </div>
      </div>
      <div
        className={`${
          show ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 z-[100] left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div onClick={() => setShow(false)}>
            <img
              src={logo}
              alt=""
              className="cursor-pointer h-[4rem] w-[4rem]"
            />
          </div>
          <p className="text-center mt-[1rem] mb-5">{err}</p>
          <button
            onClick={() => setShow(false)}
            type="button"
            className="cursor-pointer rounded-xl pt-[0.5rem] pb-[0.5rem] pl-10 pr-10 text-white bg-[#DE2827] w-[60%]"
          >
            Ok
          </button>
        </div>
      </div>
      <div
        className={`${
          showConfirm ? "" : "hidden"
        } w-[100%] h-[100%] fixed top-0 z-[100] left-0 bg-slate-500/50 flex justify-center items-center`}
      >
        <div className="relative rounded-xl w-[20rem] pb-6 flex items-center p-3 bg-white flex-col">
          <div onClick={() => setShowConfirm(false)}>
            <img
              src={logo}
              alt=""
              className="cursor-pointer h-[4rem] w-[4rem]"
            />
          </div>
          <p className="text-center mt-[1rem] mb-5">
            Data Imported Successfully.
          </p>
        </div>
      </div>
    </header>
  );
};

export default MobileHeader;

import React from "react";
// import {
//   LineChart,
//   BarChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Legend,
//   Bar,
//   Cell,
//   Tooltip,
//   PieChart,
//   Pie,
//   ResponsiveContainer,
// } from "recharts";
// import arrow from "../../../Assets/arrow.png";
// import profile from "../../../Assets/c.jpg";
// import { useDispatch } from "react-redux";

// import {
//   LeftOutlined,
//   RightOutlined,
//   PlusOutlined,
//   EllipsisOutlined,
// } from "@ant-design/icons";

const Main = () => {
  // const dispatch = useDispatch();
  // const dataP = [
  //   { name: "Region 1", value: 400 },
  //   { name: "Region 2", value: 300 },
  //   { name: "Region 3", value: 300 },
  //   { name: "Region 4", value: 200 },
  // ];

  // const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  // const renderCustomizedLabel = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  // }) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
  //   const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill="white"
  //       textAnchor="middle"
  //       dominantBaseline="central"
  //     >
  //       {`${(percent * 100).toFixed(0)}%`}
  //     </text>
  //   );
  // };
  // const data = [
  //   { name: "Jan", pv: 240 },
  //   { name: "Feb", pv: 139 },
  //   { name: "Mar", pv: 680 },
  //   { name: "Apr", pv: 390 },
  //   { name: "May", pv: 480 },
  //   { name: "Jun", pv: 380 },
  //   { name: "Jul", pv: 600 },
  //   { name: "Aug", pv: 430 },
  //   { name: "Sep", pv: 750 },
  //   { name: "Oct", pv: 430 },
  //   { name: "Nov", pv: 720 },
  //   { name: "Dec", pv: 430 },
  // ];
  // const beneficiaries = [
  //   {
  //     profilePicture: profile,
  //     name: "John",
  //   },
  //   {
  //     profilePicture: profile,
  //     name: "Jane",
  //   },
  //   {
  //     profilePicture: profile,
  //     name: "Bakar",
  //   },
  // ];

  // const dataB = [
  //   {
  //     name: "J",
  //     pv: 2400,
  //   },
  //   {
  //     name: "F",
  //     pv: 1398,
  //   },
  //   {
  //     name: "M",
  //     pv: 5900,
  //   },
  //   {
  //     name: "A",
  //     pv: 3908,
  //   },
  //   {
  //     name: "M",
  //     pv: 4800,
  //   },
  // ];
  // // dispatch({ type: "SIDEBAR", num: "" });
  // const data01 = [{ name: "Total", value: 9670 }];
  // const data02 = [
  //   { name: "Employees", value: 1000 },
  //   { name: "Admin", value: 3000 },
  //   { name: "SuperAdmin", value: 2000 },
  // ];

  // const CustomTooltip = ({ active, payload }) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="bg-white flex gap-2 rounded-xl shadow-xl p-5">
  //         <p className="font-semibold ">{`${payload[0].name} :-  `} </p>
  //         <p className="">{`${payload[0].value}`}</p>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  return (
    <div className="">
      <iframe
        className="h-[87.5vh]"
        title="Mohsss_Report"
        width="100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiZWIzYzczNWMtNWQ1ZC00NWJiLWFmNGYtMTVhNzAwMzM3NDA3IiwidCI6IjE1Y2M4YzAxLTQ0M2EtNDNmOS1iODQxLTM3YjI3MjUxZjFjYyJ9"
        frameBorder="0"
        allowFullScreen={true}
      ></iframe>
    </div>
    // <div className="w-full bg-gray-100 md:pl-4 md:pr-4">
    //   <div className="w-full flex flex-col gap-4 pt-6 pb-6 p-2">
    //     <div className="w-full md:flex-row flex-col flex gap-4">
    //       <div className="md:w-[70%] w-full h-full flex flex-col gap-2">
    //         <div className="w-full">
    //           <div className="w-full flex  sm:flex-row flex-col items-center justify-center gap-4">
    //             <div className="h-full sm:w-[32%] w-full flex flex-col rounded-xl p-5 cursor-pointer bg-purple-400">
    //               <h1 className="text-white font-semibold text-sm mb-2">
    //                 No. Of Members
    //               </h1>
    //               <div className=" flex items-center text-white text-xl font-semibold">
    //                 948
    //               </div>
    //               <div className=" flex items-center text-white text-sm">
    //                 123 more then last month
    //               </div>
    //             </div>
    //             <div className="h-full flex flex-col  sm:w-[32%] w-full rounded-xl p-5 bg-red-400 cursor-pointer">
    //               <h1 className="text-white font-semibold text-sm mb-2">
    //                 Payouts
    //               </h1>
    //               <div className=" flex items-center text-white text-xl font-semibold">
    //                 $9,234.48
    //               </div>
    //               <div className=" flex items-center text-white  text-sm">
    //                 134.48$ more then last month
    //               </div>
    //             </div>
    //             <div className="h-full sm:w-[32%] w-full flex flex-col rounded-xl p-5 bg-green-400 cursor-pointer">
    //               <h1 className="text-white font-semibold text-sm mb-2">
    //                 Programme Participants
    //               </h1>
    //               <div className=" flex items-center justify-between text-white text-xl font-semibold">
    //                 <div className="flex flex-col items-center justify-center">
    //                   <h1 className="font-semibold  text-[18px] mt-1">
    //                     Approved
    //                   </h1>
    //                   <p className=" flex items-center text-white  text-sm">
    //                     498
    //                   </p>
    //                 </div>
    //                 <div className="flex flex-col items-center justify-center">
    //                   <h1 className="font-semibold text-[18px] mt-1">
    //                     Rejected
    //                   </h1>
    //                   <p className=" flex items-center text-white  text-sm">
    //                     098
    //                   </p>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="p-4 w-full bg-white shadow-2xl rounded-xl">
    //           <div className="flex items-center justify-between">
    //             <h1 className="font-semibold">Payouts</h1>
    //             <select className="border-none bg-none outline-none">
    //               <option value="" selected hidden>
    //                 Month
    //               </option>
    //               <option value="january">January</option>
    //               <option value="february">February</option>
    //               <option value="march">March</option>
    //               <option value="april">April</option>
    //               <option value="may">May</option>
    //               <option value="june">June</option>
    //               <option value="july">July</option>
    //               <option value="august">August</option>
    //               <option value="september">September</option>
    //               <option value="october">October</option>
    //               <option value="november">November</option>
    //               <option value="december">December</option>
    //             </select>
    //           </div>
    //           <ResponsiveContainer width="100%" height={215} className="mt-4">
    //             <LineChart data={data}>
    //               <CartesianGrid strokeDasharray="2 2" strokeOpacity={0.5} />
    //               <XAxis dataKey="name" />
    //               <YAxis />
    //               <Tooltip />
    //               <Line type="monotone" dataKey="pv" stroke="#8884d8" />
    //             </LineChart>
    //           </ResponsiveContainer>
    //         </div>
    //       </div>
    //       <div className="md:w-[30%] w-full  h-full rounded-xl flex flex-col gap-4">
    //         <div className="w-full bg-white rounded-xl shadow-xl flex flex-col gap-4 p-4">
    //           <div className="w-full">
    //             <div className="w-full flex w-full flex-col h-full">
    //               <div className="w-full flex items-center justify-between">
    //                 <h1 className="font-semibold">Programs</h1>
    //                 <div className="flex gap-2">
    //                   <LeftOutlined /> <RightOutlined />
    //                 </div>
    //               </div>
    //               <div className="w-full  mt-2 flex flex-col gap-4 bg-purple-500 rounded-2xl p-4 pl-5 pr-5 shadow-2xl">
    //                 <div className="flex items-center h-[20%] justify-between">
    //                   <h1 className="font-semibold text-lg text-white flex items-center justify-between w-full">
    //                     All Programs:{" "}
    //                     <span className="text-xl ">75,00,000</span>
    //                   </h1>
    //                   {/* <img src={card} alt="" className="w-8 h-5" /> */}
    //                 </div>

    //                 <div className="flex items-center h-[20%] justify-between">
    //                   <div>
    //                     <div className="flex items-center justify-center flex-col">
    //                       <h1 className="text-xs text-white">Program</h1>
    //                       <h1 className="text-sm font-semibold text-white">
    //                         SCP
    //                       </h1>
    //                     </div>
    //                   </div>
    //                   <div>
    //                     <div className="flex items-center justify-center flex-col">
    //                       <h1 className="text-xs text-white">Total</h1>
    //                       <h1 className="text-sm font-semibold text-white">
    //                         $34,000
    //                       </h1>
    //                     </div>
    //                   </div>
    //                   <div>
    //                     <div>
    //                       <h1 className="text-xs text-white">Last Updated</h1>
    //                       <h1 className="text-sm font-semibold text-white">
    //                         03/26
    //                       </h1>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="flex items-center h-[40%] justify-between">
    //                   <div className="font-semibold text-lg text-white flex items-center justify-between w-full">
    //                     {" "}
    //                     {/* <p className="text-xl flex">.... </p> */}
    //                     <p className=" ">Total Pensioners:</p>
    //                     {/* <p className="text-xl ">.... </p> */}
    //                     <p className="text-xl ">722433</p>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>

    //           <div className="w-full flex h-[2rem] items-center justify-between mb-2">
    //             <div className="w-[29%] cursor-pointer h-full flex gap-1 items-center justify-center bg-gray-100 rounded-xl shadow-lg">
    //               <img src={arrow} alt="" className="w-5 h-5" />
    //               <h1 className="text-sm font-semibold">Send</h1>
    //             </div>
    //             <div className="w-[32%] h-full cursor-pointer flex gap-1  items-center justify-center bg-gray-100 rounded-xl shadow-lg">
    //               <img src={arrow} alt="" className="w-5 h-5" />
    //               <h1 className="text-sm font-semibold">Request</h1>
    //             </div>
    //             <div className="w-[29%] h-full cursor-pointer flex gap-1  items-center justify-center h-full bg-gray-100 rounded-xl shadow-lg">
    //               <PlusOutlined className="w-5 h-5" />
    //               <h1 className="text-sm font-semibold">Import</h1>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="w-full md:h-[30%]  bg-white p-4 rounded-xl shadow-xl">
    //           <div className="bg-white h-full">
    //             <h2 className="text-lg font-semibold">
    //               Platform Accessed By Employees
    //             </h2>
    //             <div className="flex items-center mt-2 w-full gap-x-4">
    //               <div className="flex items-center cursor-pointer justify-center flex-col">
    //                 <div className="h-8 w-8 rounded-full mb-[6px] bg-gray-200 flex items-center justify-center">
    //                   <PlusOutlined className=" flex items-center justify-center w-5 h-5" />
    //                 </div>
    //                 <p className="text-xs font-semibold">Add</p>
    //               </div>
    //               {beneficiaries.map((beneficiary, index) => (
    //                 <div
    //                   key={index}
    //                   className="flex items-center cursor-pointer justify-center flex-col"
    //                 >
    //                   <img
    //                     src={beneficiary.profilePicture}
    //                     alt={beneficiary.name}
    //                     className="h-8 w-8 rounded-full mb-1"
    //                   />
    //                   <p className="text-xs font-semibold">
    //                     {beneficiary.name}
    //                   </p>
    //                 </div>
    //               ))}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="w-full flex md:flex-row flex-col gap-4">
    //       <div className="md:w-[60%] w-full flex flex-col gap-4">
    //         <div className="flex sm:flex-row  border h-full flex-col gap-4 w-full">
    //           <div className="sm:w-[50%] w-full flex flex-col gap-2 bg-white p-4 rounded-xl shadow-xl">
    //             <div className="w-full flex items-center justify-between">
    //               <h2 className="text-lg font-semibold">Distributions</h2>
    //               <EllipsisOutlined className="text-xl cursor-pointer" />
    //             </div>
    //             <div className="flex gap-1">
    //               <div className="w-[40%] flex flex-col mt-5">
    //                 <div className="w-full flex gap-2">
    //                   <div className="flex flex-col gap-[3px]">
    //                     <h1 className="text-green-500 font-semibold">
    //                       + 30.00%
    //                     </h1>
    //                     <div className=" flex items-center text-black text-xl font-semibold">
    //                       $448.48
    //                     </div>
    //                     <div className=" flex items-center text-gray-600">
    //                       Total Amount
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="sm:w-[60%]">
    //                 <ResponsiveContainer width="100%" height="100%">
    //                   <BarChart width={150} height={100} data={dataB}>
    //                     <XAxis
    //                       dataKey="name"
    //                       axisLine={false}
    //                       tick={{ fontSize: 10 }}
    //                       tickMargin={16}
    //                     />
    //                     <Tooltip />
    //                     <Bar
    //                       dataKey="pv"
    //                       fill="#8884d8"
    //                       radius={[8, 8, 0, 0]}
    //                       strokeWidth={1}
    //                     />
    //                   </BarChart>
    //                 </ResponsiveContainer>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="sm:w-[50%] w-full sm:h-full h-[12rem] p-4  bg-white rounded-xl shadow-xl">
    //             <ResponsiveContainer width="100%" height="100%">
    //               <PieChart width={10} height={10}>
    //                 <Pie
    //                   data={dataP}
    //                   cx="50%"
    //                   cy="50%"
    //                   labelLine={false}
    //                   label={renderCustomizedLabel}
    //                   outerRadius={65}
    //                   fill="#8884d8"
    //                   dataKey="value"
    //                 >
    //                   {dataP.map((entry, index) => (
    //                     <Cell
    //                       key={`cell-${index}`}
    //                       fill={COLORS[index % COLORS.length]}
    //                     />
    //                   ))}
    //                 </Pie>
    //                 <Tooltip content={<CustomTooltip />} />
    //                 <Legend
    //                   layout="vertical"
    //                   align="right"
    //                   verticalAlign="middle"
    //                   iconSize={10}
    //                   iconType="circle"
    //                 />
    //               </PieChart>
    //             </ResponsiveContainer>
    //           </div>
    //         </div>
    //         <div className="w-full flex flex-col gap-4 p-4 bg-white rounded-xl shadow-xl">
    //           <div className="w-full flex items-center justify-between">
    //             <h2 className="text-lg font-semibold">
    //               Recently Paid Pensioners
    //             </h2>
    //             <EllipsisOutlined className="text-xl cursor-pointer" />
    //           </div>
    //           <div className="overflow-auto w-full">
    //             <table className="border-collapse w-full">
    //               <thead>
    //                 <tr className="bg-gray-200">
    //                   <th className="px-2 text-left py-1 text-sm text-gray-600 font-semibold">
    //                     Name
    //                   </th>
    //                   <th className="px-5 text-left py-1"></th>
    //                   <th className="px-2 text-left py-1 text-sm text-gray-600 font-semibold">
    //                     Date
    //                   </th>
    //                   <th className="px-5 text-left py-1"></th>
    //                   <th className="px-2 text-left py-1 text-sm text-gray-600 font-semibold">
    //                     Amount
    //                   </th>
    //                 </tr>
    //               </thead>
    //               <tbody>
    //                 <tr className="">
    //                   <td className="px-2 pt-2 flex gap-2">
    //                     {/* <img src={paypal} alt="" className="w-5 h-5" /> */}
    //                     Oscar
    //                   </td>
    //                   <td className="px-5 pt-2"></td>
    //                   <td className="px-2 pt-2">24/01/1996</td>
    //                   <td className="px-5 pt-2"></td>
    //                   <td className="px-2 pt-2">$64,615</td>
    //                 </tr>
    //               </tbody>
    //             </table>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="md:w-[40%] w-full p-4 bg-white rounded-xl shadow-xl">
    //         <div className="flex items-center justify-between">
    //           <h1 className="font-semibold">Databases Summary</h1>
    //           <select className="border-none bg-none outline-none">
    //             <option value="" selected hidden>
    //               Month
    //             </option>
    //             <option value="january">January</option>
    //             <option value="february">February</option>
    //             <option value="march">March</option>
    //             <option value="april">April</option>
    //             <option value="may">May</option>
    //             <option value="june">June</option>
    //             <option value="july">July</option>
    //             <option value="august">August</option>
    //             <option value="september">September</option>
    //             <option value="october">October</option>
    //             <option value="november">November</option>
    //             <option value="december">December</option>
    //           </select>
    //         </div>
    //         <div className="w-full ">
    //           <ResponsiveContainer width="100%" height={250}>
    //             <PieChart>
    //               <Pie
    //                 data={data01}
    //                 dataKey="value"
    //                 cx="50%"
    //                 cy="50%"
    //                 outerRadius={65}
    //                 fill="#8884d8"
    //                 label={false}
    //               />
    //               <Pie
    //                 data={data02}
    //                 dataKey="value"
    //                 cx="50%"
    //                 cy="50%"
    //                 innerRadius={75}
    //                 outerRadius={95}
    //                 label={false}
    //               >
    //                 {data02.map((entry, index) => (
    //                   <Cell
    //                     key={`cell-${index}`}
    //                     fill={COLORS[index % COLORS.length]}
    //                   />
    //                 ))}
    //               </Pie>
    //               <Tooltip />
    //               <Legend
    //                 layout="horizontal"
    //                 align="center"
    //                 verticalAlign="bottom"
    //               />
    //             </PieChart>
    //           </ResponsiveContainer>
    //         </div>
    //         {/* <div className="mt-4">
    //           <ProgressBar data01={data01} data02={data02} />
    //         </div> */}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Main;
